<template>
  <v-container class="pd16">
    <div class="monthday">
      <v-row>
        <v-col @click="setYear(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="그룹_1299" data-name="그룹 1299" transform="translate(310 -285) rotate(90)">
              <rect id="사각형_647" data-name="사각형 647" width="36" height="36" transform="translate(285 274)"
                fill="none" />
              <path id="패스_242" data-name="패스 242" d="M-237.25,395.833l-8,8-8-8" transform="translate(548.25 -107.833)"
                fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
          </svg>
        </v-col>
        <v-col class="text-center"><strong class="fts18 lh36">{{ payYear }}</strong></v-col>
        <v-col @click="setYear(1)">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g id="그룹_381" data-name="그룹 381" transform="translate(-274 321) rotate(-90)">
              <rect id="사각형_647" data-name="사각형 647" width="36" height="36" transform="translate(285 274)"
                fill="none" />
              <path id="패스_242" data-name="패스 242" d="M-237.25,395.833l-8,8-8-8" transform="translate(548.25 -107.833)"
                fill="none" stroke="#3c3c3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
          </svg>
        </v-col>
      </v-row>
    </div>

    <div class="openbox-item2" v-for="(payment, i) in paymentList" :key="payment.paymentYearMonth + i">
      <!-- 타이틀 영역 -->
      <v-row>
        <strong class="ob-title ft-l" style="width: 100%">{{ payment.paymentYearMonth.substr(4, 2) }}월
          <span v-if="payment.payDate == null || payment.payDate == ''" class="done-pay">[미납]</span>
          <span v-else class="this-pay">[{{ payment.payDate }} 납부]</span>
          <span class="ft-r" @click="openDetail(payment, i)" v-if="i == 0 && nowYear == payYear && !hideCustomText">고지서
            열람
            <Iconsearch />
          </span>
          <!-- <span
            class="ft-r"
            @click="openDetail(payment, i)"
            v-else-if="payment.payDate != null && payment.payDate != ''"
            >납부 상세내역 <Iconsearch
          /></span> -->
        </strong>
      </v-row>

      <!-- 미납월의 경우 value=true로 list-group오픈되어있음 / TODO : value=true일때 헤더영역 빨간색 -->
      <v-list-group :value="payment.payDate == null || payment.payDate == '' ? true : false">
        <template v-slot:activator>
          <strong class="ob-title"><span>총 금액</span></strong>
          <span style="color: #000000" v-if="i == 0">{{
            payment.AM_AFTERLEASE | comma
          }}</span>
          <span style="color: #000000" v-else>{{
            payment.AM_AFTERLEASE | comma
          }}</span>
        </template>
        <div sub-group>
          <table class="open-table table2">
            <colgroup>
              <col width="33%" />
              <col width="33%" />
              <col width="*" />
              <col width="40px" />
            </colgroup>
            <tr>
              <th>임대료</th>
              <td></td>
              <td>{{ payment.rent | comma }}</td>
              <td></td>
            </tr>
            <tr>
              <th>미납임대료</th>
              <td></td>
              <td>{{ payment.delayedRent | comma }}</td>
              <td></td>
            </tr>
            <tr>
              <th>미납연체료</th>
              <td></td>
              <td>{{ (payment.AM_DELAY + payment.lateFee) | comma }}</td>
              <td></td>
            </tr>
            <tr>
              <th>미청구금액</th>
              <td></td>
              <td>{{ payment.notCharged | comma }}</td>
              <td></td>
            </tr>
            <tr v-if="payment.rentInsuranceFee > 0">
              <th>임대보증수수료</th>
              <td></td>
              <td>{{ payment.rentInsuranceFee | comma }}</td>
              <td></td>
            </tr>
            <tr style="border-top: 1px dotted #8e8e8e">
              <th>과오납차감</th>
              <td></td>
              <td>{{ payment.overCharged | comma }}</td>
              <td></td>
            </tr>
            <tr class="total" v-if="payment.payDate == null && payment.current">
              <!-- 납기일까지 입금할 경우의 총 납부액은 서버에서 계산한 대로 출력 -->
              <th>납기 내 납부 시</th>
              <td>{{ payment.paymentDate }}</td>
              <td>
                {{ payment.total | comma }}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
      </v-list-group>
    </div>

    <div class="order-list" v-if="noMsg && !hideCustomText">
      <p>※ 과오납금액은 임대료에서 차감되오니 참고바랍니다.</p>
    </div>

    <strong class="t-cont-title text-center mt-10 mb-10" v-if="!noMsg">조회 내용이 없습니다.</strong>

    <strong class="t-cont-title" v-if="noMsg && !hideCustomText">납부처 및 납부방법</strong>
    <table class="t-table table1 mb15" v-if="noMsg && !hideCustomText">
      <colgroup>
        <col width="95px" />
        <col width="*" />
      </colgroup>
      <tr>
        <th>납부기관</th>
        <td>{{ DS_BANK }}</td>
      </tr>
      <tr>
        <th>납부계좌</th>
        <td>{{ NO_GUJA }}</td>
      </tr>
      <tr>
        <th>예금주</th>
        <td>{{ DS_OWNER }}</td>
      </tr>
    </table>

    <!-- 고지서 팝업 다이얼로그 -->
    <v-dialog v-model="payDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-container class="pd16 bgc-3 vh-full">
        <strong class="close-title" style="background-color: #ffffff">
          {{ paymentDetail.YY_YEAR }}년 {{ paymentDetail.MM_MONTH }}월분 임대료
          {{ popupTitle }}
          <a @click="payDialog = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.897" height="14.897" viewBox="0 0 14.897 14.897">
              <g id="그룹_320" data-name="그룹 320" transform="translate(-299.327 -58.469)">
                <g id="그룹_318" data-name="그룹 318" transform="translate(-36.411 -67)">
                  <g id="그룹_294" data-name="그룹 294" transform="translate(10.411 88)">
                    <line id="선_59" data-name="선 59" y1="0.405" x2="16.566"
                      transform="translate(327.062 38.917) rotate(45)" fill="none" stroke="#3c3c3c"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                  <g id="그룹_318-2" data-name="그룹 318" transform="translate(298.27 465.693) rotate(-90)">
                    <line id="선_59-2" data-name="선 59" y1="0.405" x2="16.566"
                      transform="translate(327.062 38.917) rotate(45)" fill="none" stroke="#3c3c3c"
                      stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </strong>
        <table class="t-table table1 mb15">
          <colgroup>
            <col width="65px" />
            <col width="*" />
          </colgroup>
          <tr>
            <th>기간</th>
            <td>
              {{ paymentDetail.DT_FROM_YEAR }}.{{
                paymentDetail.DT_FROM_MONTH
              }}.{{ paymentDetail.DT_FROM_DAY }} ~
              {{ paymentDetail.DT_TO_YEAR }}.{{ paymentDetail.DT_TO_MONTH }}.{{
                paymentDetail.DT_TO_DAY
              }}
            </td>
          </tr>
        </table>

        <table class="open-table table2">
          <colgroup>
            <col width="50%" />
            <col width="*" />
          </colgroup>
          <tr>
            <th>당월 임대료</th>
            <td>{{ paymentDetail.AM_LEASE | comma }}</td>
          </tr>
          <tr>
            <th>미납 임대료</th>
            <td>{{ paymentDetail.AM_RECEIVABLE | comma }}</td>
          </tr>
          <!-- <tr v-if="paymentDetail.DD_DAY != null">
            <th>연체일수</th>
            <td>{{ paymentDetail.DD_DAY }}</td>
          </tr> -->
          <tr>
            <th>미납 연체료</th>
            <td>{{ paymentDetail.AM_DELAYRECEIVABLE | comma }}</td>
          </tr>
          <tr>
            <th>미청구 금액</th>
            <td>{{ paymentDetail.AM_MICHUNGGU | comma }}</td>
          </tr>
          <tr>
            <th>임대보증수수료</th>
            <td>{{ paymentDetail.AM_ASSU_TOTAL | comma }}</td>
          </tr>
          <tr>
            <th>과오납차감</th>
            <td>{{ paymentDetail.AM_OVER | comma }}</td>
          </tr>
          <tr class="total">
            <th>납기 내</th>
            <td>{{ paymentDetail.AM_LEASE_TOTAL | comma }}</td>
          </tr>
          <tr class="total">
            <th>{{ popupDate }}</th>
            <td>
              {{ paymentDetail.YY_INYEAR }}.{{ paymentDetail.MM_INMONTH }}.{{
                paymentDetail.DD_INDAY
              }}
            </td>
          </tr>
          <tr class="total rows">
            <th>납기 후 임대료</th>
            <td>연체료 일할 계산 청구</td>
          </tr>
        </table>

        <p class="text-left mt10 mb30 fts12 color-2">
          ※납부기한을 넘겨 납부하면 연체료가 일할계산되어 다음달 임대료에
          포함됩니다.
        </p>
        <strong class="t-cont-title">납부처 및 납부방법</strong>
        <table class="t-table table1 mb15">
          <colgroup>
            <col width="95px" />
            <col width="*" />
          </colgroup>
          <tr>
            <th>납부기관</th>
            <td>{{ paymentDetail.DS_BANK }}</td>
          </tr>
          <tr>
            <th>가상 납부계좌</th>
            <td>{{ paymentDetail.NO_GUJA }}</td>
          </tr>
          <tr>
            <th>예금주</th>
            <td>{{ paymentDetail.DS_OWNER }}</td>
          </tr>
          <!-- <tr>
            <th>사업자등록번호</th>
            <td>*321-81-00304</td>
          </tr>
          <tr>
            <th>임대사업자<br />등록번호</th>
            <td class="v_t">*2016-강남구-임대사업자-10441</td>
          </tr> -->
        </table>

        <strong class="t-cont-title">공지사항</strong>
        <p class="text-left mt10 mb30 fts12 color-2">
          {{ paymentDetail.DS_GONGJI }}
        </p>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import { getLeaseReceipt, getLeaseInvoice } from "@/api/contract.js";
import Iconsearch from "@/assets/images/icon/icon-search.svg";
import moment from "moment";
export default {
  components: { Iconsearch },
  name: "LeasePayment",
  props: ["village", "hideCustomText"],
  data: () => ({
    noMsg: false,
    picker: new Date().toISOString()?.substr(0, 10),
    paymentList: [], // 납부내역리스트
    paymentInfo: {},
    paymentDetail: {}, // 팝업-고지서 상세내역
    payYear: "",
    payDate: "",
    nowYear: "",
    menu: false,
    payDialog: false,
    payDateLateFee: 0,
    DS_BANK: "",
    DS_OWNER: "",
    NO_GUJA: "",
    popupDate: "납부기한",
    popupTitle: "",
  }),
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
  },
  methods: {
    init() {
      let nowdate = new Date();
      this.payYear = nowdate.getFullYear();
      this.getLeaseReceiptList();
    },
    getLeaseReceiptList() {
      // getLeaseReceipt(query).then((result) => {
      //   console.log(result);
      //   this.paymentList = result.data;
      // });

      getLeaseReceipt(this.home.id, this.payYear)
        .then(({ data }) => {
          console.log("getLeaseReceipt", data);
          this.paymentList = data.payments;
          this.paymentInfo = data.info;
          this.noMsg = true;

          console.log(this.paymentList);

          // 미납건 이후 납부가 되었으면 해당 납부일을 넣어준다.
          var lastRecieptDt = "";
          let nowdate = new Date();
          this.nowYear = nowdate.getFullYear();
          if (this.payYear < nowdate.getFullYear()) {
            // query 년도가 올해가 아닌 경우
            let queryYear = Number(this.payYear) + 1;
            getLeaseReceipt(this.home.id, queryYear).then(({ data }) => {
              var nextPaymentList = data.payments;
              nextPaymentList.forEach((item, i) => {
                if (item.payDate != "" && item.payDate != null)
                  lastRecieptDt = item.payDate;
              });

              this.paymentList.forEach((item, i) => {
                if (item.payDate != "" && item.payDate != null)
                  lastRecieptDt = item.payDate;
                else this.paymentList[i].payDate = lastRecieptDt;
              });
            });
          } else {
            // 올해년도
            this.paymentList.forEach((item, i) => {
              if (item.payDate != "" && item.payDate != null)
                lastRecieptDt = item.payDate;
              else this.paymentList[i].payDate = lastRecieptDt;
            });
          }

          let yearMonth = this.paymentList[0].paymentYearMonth;
          // 첫번째 껄로 걍 납부방법 가져옴~
          // let query = `contract_id=${this.home.id}&year=${
          //   this.payYear
          // }&&year_month=${yearMonth.substring(4)}`;
          getLeaseInvoice(
            this.home.id,
            this.payYear,
            yearMonth.substring(4)
          ).then((result) => {
            console.log(result);
            this.DS_OWNER = result.data.DS_OWNER;
            this.NO_GUJA = result.data.NO_GUJA;
            this.DS_BANK = result.data.DS_BANK;
          });
        })
        .catch((e) => {
          console.log(e);
          this.paymentList = [];
          this.noMsg = false;
        });
    },
    setYear(val) {
      this.payYear += val;
      this.getLeaseReceiptList();
    },
    openDetail(payment, i) {
      // 임대료 고지서 팝업 open
      //if (payment.payDate == null) {
      if (payment.payDate == "" || payment.payDate == null) {
        this.popupDate = "납부기한";
      } else {
        this.popupDate = "납부일자";
      }

      // 고지서, 납부 상세내역 타이틀 표기
      //popupTitle
      if (i == 0) {
        this.popupTitle = "고지서";
      } else {
        this.popupTitle = "납부 상세내역";
      }
      let yearMonth = payment.paymentYearMonth;
      console.log("MONTH:", yearMonth, yearMonth.substring(4));
      this.payDialog = true;
      // let year_month = this.payYear + "" + this.month;
      // let query = `contract_id=${this.home.id}&year=${
      //   this.payYear
      // }&&year_month=${yearMonth.substring(4)}`;
      getLeaseInvoice(this.home.id, this.payYear, yearMonth.substring(4)).then(
        (result) => {
          console.log("getLeaseInvoice", result);
          this.paymentDetail = result.data;
        }
      );
      //}
    },
    changeReceiptDt(userWantDate, payment) {
      // TODO : 예정 납부일 변경 시 연체료 계산
      console.log(`userWantDate: ${userWantDate} payment: ${payment}`);
      console.log(
        `diff: ${moment
          .duration(moment(userWantDate).diff(moment(payment.paymentDate)))
          .asDays()}`
      );
      var lateFee =
        (payment.delayedRent *
          (this.paymentInfo.baseInterestRate +
            this.paymentInfo.additionalInterestRate) *
          moment
            .duration(moment(userWantDate).diff(moment(payment.paymentDate)))
            .asDays()) /
        365;
      console.log(`newLateFee: ${lateFee}`);
      this.payDateLateFee = lateFee;
    },
  },
  created() {
    this.init();
  },
};
</script>
