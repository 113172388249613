// Village: 단지안내
import VillageInfoGrid from "@/views/VillageViews/VillageInfoGrid.vue";
import VillageInfo from "@/views/VillageViews/VillageInfo.vue";

// Parcelling: 분양일정
import ParcellingCalendarGrid from "@/views/VillageViews/ParcellingCalendarGrid.vue";

// SmartLynn: smartLynn 소개
import smartLynn from "@/views/VillageViews/SmartLynn.vue";

// WaitRequest: 대기신청
import waitRequestForm from "@/views/VillageViews/WaitRequestForm.vue";
import waitRequestView from "@/views/VillageViews/WaitRequestView.vue";

var villageRoutes = [
  // 단지안내
  {
    path: "/villageInfoList",
    name: "villageInfoList",
    component: VillageInfoGrid,
    meta: {
      header: {
        title: "단지안내",
        noProfile: true,
      },
      bottomNav: true,
      activeMenu: "info",
    },
  },
  {
    path: "/villageInfo/:id",
    name: "villageInfo",
    component: VillageInfo,
    meta: {
      bottomNav: true,
      activeMenu: "info",
    },
  },

  // 분양일정
  {
    path: "/parcellingCalendar",
    name: "parcellingCalendar",
    component: ParcellingCalendarGrid,
    meta: {
      header: {
        title: "분양일정",
        noProfile: true,
      },
      bottomNav: true,
      activeMenu: "info", // schedule
    },
  },

  // smartLynn 소개
  {
    path: "/smartLynn",
    name: "smartLynn",
    component: smartLynn,
    meta: {
      header: {
        title: "SMART LYNN 소개",
        noProfile: true,
        bgWhite: true,
      },
      bottomNav: true,
      activeMenu: "introduce",
    },
  },

  // 대기신청
  {
    path: "/waitRequestForm",
    name: "waitRequestForm",
    component: waitRequestForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/waitRequestView",
    name: "waitRequestView",
    component: waitRequestView,
    meta: {
      requiresAuth: true,
    },
  },
];

export { villageRoutes };
