<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mypi-web">
      <div class="mypi-contbox">
        <!-- <div class="mypi-contbox" v-show="win_reservations.length">
          <strong class="t-cont-title">당첨자 방문예약</strong>
          <ul class="mypi-long">
            <li v-for="item in win_reservations" :key="'win-reservations-' + item.id" :class="win_reservations.length == 1 ? 'single' : ''" @click.stop="loadWinVerification(item)">
              <router-link to >
                <span class="left">
                  <Iconsample />
                  <span class="sidetop">{{item.village_name}}</span>
                  {{item.title}}
                  <span class="side" v-if="item.status_text">[{{item.status_text}}]</span>
                </span>
              </router-link>
            </li>
          </ul>
        </div> -->

        <div v-if="contractHomes.length" class="mypi-contbox">
          <strong class="t-cont-title mb15">계약세대</strong>
          <ul class="member-box">
            <li v-for="item in contractHomes" :key="item.id">
              <div class="member-item">
                <div class="top">
                  <span class="name">{{ item.village_name }}</span>
                </div>
                <p class="phone">{{ item.dong_ho_text }}</p>
                <div class="bottom">
                  <span class="text">{{ item.status_text }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="tenantHomes.length" class="mypi-contbox">
          <strong class="t-cont-title mb15">입주세대</strong>
          <ul class="member-box">
            <li v-for="item in tenantHomes" :key="item.id">
              <div class="member-item">
                <div class="top">
                  <span class="name">{{ item.village_name }}</span>
                </div>
                <p class="phone">{{ item.dong_ho_text }}</p>
                <div class="bottom" v-if="!user.is_accepted">
                  <span class="text" v-if="item.is_host">
                    관리사무소 승인대기중</span>
                  <span class="text" v-if="!item.is_host">
                    세대관리자 승인대기중</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mypi-contbox">
          <strong class="t-cont-title">단지시설 신청내역</strong>
          <ul class="mypi-long">
            <li v-for="item in facility_reservations.slice(0, 4)" :key="'facility-reservations-' + item.id">
              <router-link to="/facilityHistory">
                <span class="left pl0">{{ item.facility.name }}</span>
                <span class="right pl0">
                  <span class="text">{{ item.date_form }}</span>
                </span>
              </router-link>
            </li>
          </ul>
          <span class="no-list" v-if="!facility_reservations.length">
            단지시설 신청 내역이 없습니다.
          </span>
          <v-btn v-if="facility_reservations.length > 4" color="grey darken-1" text :ripple="false" :plain="true"
            @click="$router.push({ name: 'facilityHistory' })">
            <span>
              <v-icon>mdi-arrow-down</v-icon>
              더보기
            </span>
          </v-btn>
        </div>

        <strong class="t-cont-title">예약/신청 내역</strong>
        <ul class="mypi-long">
          <li v-for="item in brief_reservations" :key="'reservation-' + item.id"
            :class="{ single: brief_reservations.length == 1 }">
            <a @click="reservationView(item.type, item.path)">
              <span class="left">
                <Iconsample v-show="item.type == 'VISIT_RESERVATION'" />
                <Iconsample v-show="item.type == 'CONTRACT_RESERVATION'" />
                <Iconsample v-show="item.type == 'PAYMENT_RESERVATION'" />
                <Iconsample v-show="item.type == 'JOINT_RESERVATION'" />
                <Iconcheck v-show="item.type == 'PRE_CHECK_RESERVATION'" />
                <Iconmovein v-show="item.type == 'MOVEIN_RESERVATION'" />
                <Iconsample v-show="[
                  'WIN_RESERVATION',
                  'PRE_WIN_RESERVATION',
                  'MOVEIN_WIN_RESERVATION',
                ].includes(item.type)
                  " />
                <span v-if="item.type == 'WIN_RESERVATION'" style="font-size:12px;">
                  {{ item.master_title }}
                </span>
                <span v-else>
                  {{ item.text }}
                </span>
                <!-- {{ item.master_title }} -->
                <span class="side" v-show="item.status_text">
                  [{{ item.status_text }}]
                </span>
              </span>
              <span class="right">
                <span class="text">{{ item.date }} | {{ item.time }}</span>
              </span>
            </a>
          </li>
        </ul>
        <span class="no-list" v-if="!reservations.length">
          예약 및 신청 내역이 없습니다.
        </span>
        <v-btn color="grey darken-1" text :ripple="false" :plain="true"
          @click="isBriefLoad.reservations = !isBriefLoad.reservations" v-show="reservations.length > 3">
          <span v-if="isBriefLoad.reservations">
            <v-icon>mdi-arrow-down</v-icon>
            더보기
          </span>
          <span v-else>
            <v-icon>mdi-arrow-up</v-icon>
            간략히
          </span>
        </v-btn>
      </div>

      <!-- <div class="mypi-contbox">
          <strong class="t-cont-title">하자 접수</strong>
          <ul class="mypi-long">
            <li>
              <router-link to="/inspectionList">
                <span class="left"><Iconcheck/>사전점검</span>
                <span class="flexSeries">

                  <span class="infBox in">
                    <span class="infbTop">접수</span>
                    <span class="infbbottom">0건</span>
                  </span>
                  <span class="infBox not">
                    <span class="infbTop">미처리</span>
                    <span class="infbbottom">0건</span>
                  </span>

                  <span class="infBox not">
                    <span class="infbTop">접수</span>
                    <span class="infbbottom"><span>6</span>건</span>
                  </span>
                  <span class="infBox in">
                    <span class="infbTop">완료</span>
                    <span class="infbbottom"><span>33</span>건</span>
                  </span>
                </span>
              </router-link>
            </li>
            <li>
              <router-link to="/defectsList">
                <span class="left"><Iconcheck/>입주하자</span>
                <span class="flexSeries">
                  
                  <span class="infBox not">
                    <span class="infbTop">미처리</span>
                    <span class="infbbottom">0건</span>
                  </span>
                  
                  <span class="infBox not">
                    <span class="infbTop">접수</span>
                    <span class="infbbottom"><span>6</span>건</span>
                  </span>
                  <span class="infBox in">
                    <span class="infbTop">완료</span>
                    <span class="infbbottom"><span>61</span>건</span>
                  </span>
                </span>
              </router-link>
            </li>
          </ul>
        </div> -->

      <div class="mypi-contbox">
        <strong class="t-cont-title">관심단지</strong>
        <ul class="mypi-long">
          <li v-for="item in brief_interested_villages" :key="'interested-villages-' + item.id"
            v-bind:class="{ single: brief_interested_villages.length == 1 }">
            <router-link :to="item.path">
              <span class="left">
                <Iconheart />
                {{ item.text }}
              </span>
            </router-link>
          </li>
        </ul>
        <span class="no-list" v-if="!interested_villages.length">
          등록된 관심단지가 없습니다.
        </span>
        <v-btn color="grey darken-1" text :ripple="false" :plain="true" @click="
          isBriefLoad.interested_villages = !isBriefLoad.interested_villages
          " v-show="interested_villages.length > 3">
          <span v-if="isBriefLoad.interested_villages">
            <v-icon>mdi-arrow-down</v-icon>
            더보기
          </span>
          <span v-else>
            <v-icon>mdi-arrow-up</v-icon>
            간략히
          </span>
        </v-btn>
      </div>

      <div class="mypi-contbox" v-if="rental_requests.length">
        <strong class="t-cont-title">임대단지 신청 내역</strong>
        <ul class="mypi-long">
          <li v-for="item in brief_rental_requests" :key="'rental-requests-' + item.id"
            v-bind:class="{ single: brief_rental_requests.length == 1 }">
            <router-link :to="item.path">
              <span class="left">
                {{ item.text }}
                <Iconcontract v-if="item.type == 'CONTRACT'" />
                <Iconwait v-else />
                <span class="side" v-show="item.status_text">
                  [{{ item.status_text }}]
                </span>
              </span>
              <span class="right">
                <span class="text" v-if="item.type == 'CONTRACT'">
                  {{ item.date }} | {{ item.time }}
                </span>
                <span class="text" v-else-if="showNumber(item.village_name) && item.wait_number">
                  대기순번 : {{ item.wait_number | comma }}
                </span>
                <span class="text"> {{ item.village_name }} </span>
              </span>
            </router-link>
          </li>
        </ul>
        <v-btn color="grey darken-1" text :ripple="false" :plain="true"
          @click="isBriefLoad.rental_requests = !isBriefLoad.rental_requests" v-show="rental_requests.length > 3">
          <span v-if="isBriefLoad.rental_requests">
            <v-icon>mdi-arrow-down</v-icon>
            더보기
          </span>
          <span v-else>
            <v-icon>mdi-arrow-up</v-icon>
            간략히
          </span>
        </v-btn>
      </div>

      <div class="mypi-contbox" v-if="contract_change_requests.length">
        <strong class="t-cont-title">계약 변경/갱신/해지 신청 내역</strong>
        <ul class="mypi-long">
          <li v-for="item in brief_contract_change_requests" :key="'contract-change-' + item.id">
            <router-link :to="item.path">
              <span class="left">
                <Iconcontract />
                {{ item.text }}
                <span class="side" v-show="item.status_text">
                  [{{ item.status_text }}]
                </span>
              </span>
              <span class="right">
                <span class="text">{{ item.date }} </span>
                <span class="text"> {{ item.village_name }} </span>
              </span>
            </router-link>
          </li>
        </ul>
        <span class="no-list" v-if="!contract_change_requests.length">
          예약 및 신청 내역이 없습니다.
        </span>
        <v-btn color="grey darken-1" text :ripple="false" :plain="true" @click="
          isBriefLoad.contract_change_requests = !isBriefLoad.contract_change_requests
          " v-show="contract_change_requests.length > 3">
          <span v-if="isBriefLoad.contract_change_requests">
            <v-icon>mdi-arrow-down</v-icon>
            더보기
          </span>
          <span v-else>
            <v-icon>mdi-arrow-up</v-icon>
            간략히
          </span>
        </v-btn>
      </div>

      <div class="mypi-contbox" v-if="leaseContract">
        <strong class="t-cont-title">문서 출력</strong>
        <ul class="mypi-long">
          <li>
            <a v-on:click="checkCanOpen('deposit')">
              <span class="left">
                <Iconcontract />
                보증금 납부 명세서
              </span>
            </a>
          </li>
          <li>
            <a>
              <span class="left">
                <Iconcontract />
                임대료 납부내역서
              </span>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on" elevation="0" height="1.5rem">
                    연도선택
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in rentFeeList" :key="index" dense="true"
                    v-on:click="setRentFeeYearAndOpen(item)">
                    <v-list-item-title>{{ item }}년</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </a>
            <span> </span>
          </li>
          <li v-if="contractChange">
            <a v-on:click="checkCanOpen('renewal')">
              <span class="left">
                <Iconcontract />
                임대차 재계약 동의서
              </span>
            </a>
          </li>
          <li v-if="!hideRightMenu">
            <a v-on:click="checkCanOpen('right')">
              <span class="left">
                <Iconcontract />
                임차보증금에 대한 권리침해유무확인서
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div class="mypi-contbox last" v-if="isLogin">
        <ul class="mypi-nomal">
          <li>
            <router-link to="/memberInfoUpdate">
              <span class="text">회원정보수정</span>
              <span class="icon">
                <Iconmodify />
              </span>
            </router-link>
          </li>
          <li>
            <a @click.prevent.stop="signOutDialog = true">
              <span class="text">로그아웃</span>
              <span class="icon">
                <Iconlogout />
              </span>
            </a>
          </li>
          <!-- <li>
            <a @click.stop="loadContractorVerification()">
              <span class="text color-1 lh24">계약자 인증하기</span>
              <span class="icon pd0">
                <v-icon color="#f36628">mdi-plus-circle-outline </v-icon></span
              >
            </a>
          </li> -->
          <li>
            <a @click.stop="loadVerification()">
              <span class="text lh22">계약/입주자 인증</span>
              <span class="icon pd0">
                <v-icon color="#333333" size="22">mdi-plus-circle-outline
                </v-icon></span>
            </a>
          </li>

          <li v-if="user.is_superuser">
            <a @click.stop="loadContractorVerification(true)">
              <span class="text deep-purple--text lh22">테스트 인증</span>
              <span class="icon pd0">
                <v-icon color="deep-purple" size="22">
                  mdi-account-cog-outline
                </v-icon></span>
            </a>
          </li>

          <li v-if="user.is_accepted">
            <router-link to="/familyMember">
              <span class="text">가족구성원</span>
              <span class="icon">
                <Iconfamily />
              </span>
            </router-link>
          </li>

          <!-- 당첨자 예약 테스트 해봤음 ~ -->
          <!-- <li>
            <a @click.stop="loadWinVerification()">
              <span class="text">테스트</span>
              <span class="icon"><Iconfamily /></span>
            </a>
          </li> -->
        </ul>
      </div>

      <!-- 로그아웃 다이얼로그 -->
      <v-dialog v-model="signOutDialog" max-width="290">
        <v-card>
          <v-card-title class="justify-center"> </v-card-title>
          <v-card-text class="text-left ftw7">
            로그아웃 하시겠습니까?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="signOutDialog = false">
              취소
            </v-btn>
            <v-btn color="primary darken-1" text @click="signOut()">
              로그아웃
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loadingDialog" max-width="290">
        로딩중입니다.
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import moment from "moment";
import Iconsample from "@/assets/images/mypage/icon-sample.svg"; // 견본주택방문
import Iconcheck from "@/assets/images/mypage/icon-check.svg"; // 사전점검
import Iconmovein from "@/assets/images/mypage/icon-movein.svg"; // 입주
import Iconheart from "@/assets/images/mypage/icon-heart.svg"; // 관심등록
import Iconcontract from "@/assets/images/mypage/icon-contract.svg"; // 계약신청
import Iconwait from "@/assets/images/mypage/icon-wait.svg"; // 대기신청
import Iconmodify from "@/assets/images/mypage/icon-modify.svg"; // 회원정보수정
import Iconlogout from "@/assets/images/mypage/icon-logout.svg"; // 로그아웃
import Iconfamily from "@/assets/images/mypage/icon-family.svg"; // 가족구성원

import {
  getUserRequestHistory,
  getRentalContractChangeList,
  getMoveInReservation,
} from "@/api/index.js";
import {
  depositCheck,
  rentFeeCheck,
  renewalCheck,
  rightCheck,
  getPdfBlob,
} from "@/api/report";

export default {
  components: {
    Iconsample,
    Iconcheck,
    Iconmovein,
    Iconheart,
    Iconcontract,
    Iconwait,
    Iconmodify,
    Iconlogout,
    Iconfamily,
  },
  name: "Mypage",
  data: () => ({
    signOutDialog: false,
    IconsampleSVG: require("@/assets/images/mypage/icon-sample.svg"),
    request: {},

    isBriefLoad: {
      facility_reservations: true,
      reservations: true,
      interested_villages: true,
      rental_requests: true,
      contract_change_requests: true,
    },

    rentFeeYear: "",
    contractChange: null,
    loadingDialog: false,

    TEST_OPTION: true, // This is Temporary test option(should be deleted after 04.08)
    API_URL: process.env.VUE_APP_API_ENDPOINT,
  }),
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    win_reservations() {
      if (!this.request.win_reservations) return [];
      return this.request.win_reservations;
    },
    facility_reservations() {
      if (!this.request.facility_reservations) return [];
      return this.request.facility_reservations;
    },
    reservations() {
      if (!this.request.reservations) return [];
      return this.request.reservations;
    },
    brief_reservations() {
      if (this.isBriefLoad.reservations) {
        return this.reservations.slice(0, 3);
      } else {
        return this.reservations;
      }
    },
    interested_villages() {
      if (!this.request.interested_villages) return [];
      return this.request.interested_villages;
    },
    brief_interested_villages() {
      if (this.isBriefLoad.interested_villages) {
        return this.interested_villages.slice(0, 3);
      } else {
        return this.interested_villages;
      }
    },
    rental_requests() {
      if (!this.request.rental_requests) return [];
      return this.request.rental_requests;
    },
    brief_rental_requests() {
      if (this.isBriefLoad.rental_requests) {
        return this.rental_requests.slice(0, 3);
      } else {
        return this.rental_requests;
      }
    },
    contract_change_requests() {
      if (!this.request.contract_change_requests) return [];
      return this.request.contract_change_requests;
    },
    brief_contract_change_requests() {
      if (this.isBriefLoad.contract_change_requests) {
        return this.contract_change_requests.slice(0, 3);
      } else {
        return this.contract_change_requests;
      }
    },
    isLogin() {
      return this.$store.state.auth.isLoggedIn;
    },
    user() {
      return this.$store.getters.getUser;
    },
    tenantHome() {
      return this.$store.getters.getTenantHome;
    },
    rentFeeList() {
      let mo = moment();
      return [mo.format("YYYY"), mo.subtract(1, "years").format("YYYY")];
    },
    contractList() {
      return this.$store.getters.getContractList;
    },
    leaseContract() {
      return this.home?.is_rent ? this.home : null;
    },
    contractHomes() {
      return this.$store.getters.getContractList;
    },
    tenantHomes() {
      return this.$store.getters.getTenantList;
    },
    hideRightMenu() {
      return this.tenantHomes && this.tenantHomes.length > 0 && this.tenantHomes[0].erp_village_id == 'AA00P0267D';
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("signOut", {}).then(() => {
        this.$store.commit("SUCCESS", "로그아웃 되었습니다.");
        this.$router.push({ name: "intro" });
        this.signOutDialog = false;
      });
    },
    loadContractorVerification(is_test = false) {
      this.$store.commit("SET_VERIFICATON", {
        title: "계약 인증하고자 하는 단지 및 세대를 지정하세요",
        dialog: true,
        redirect: "mypage",
        is_test: is_test,
      });
    },
    loadVerification() {
      // 계약/입주자인증 버튼 클릭 시, 이전 경고 문구가 남아 있는 경우가 있어 우선 해당 위치에서 지웁니다~~
      this.$store.commit("INFO_UPDATE", {
        type: "signupcheckwarn",
        text: "",
      });

      this.$store.commit("SET_VERIFICATON", {
        title: "실명인증",
        verified_name_type: true,
        dialog: true,
        redirect: "mypage",
        is_test: false,
      });
    },
    loadWinVerification(item) {
      this.$store.commit("SET_WINVERIFICATON", {
        title: item.title,
        dialog: true,
        reservation_master: item.id,
        site_code: item.site_code,
        name: this.user.name,
      });
    },
    getRequestList() {
      getUserRequestHistory(this.user.id).then((res) => {
        this.request = res.data;

        this.request.reservations = this.request.reservations.filter(
          (r) => r.type != "MOVEIN_RESERVATION"
        );

        console.log(this.request.reservations);
        getMoveInReservation("kind_code=MOVEIN_RESERVATION").then((res) => {
          res.data.forEach((seed) => {
            0;

            seed.text = "입주 예약";
            seed.type = "MOVEIN_RESERVATION";
            seed.time = seed.timeinfo.text;
            seed.date = seed.date.replaceAll("-", "/");
            this.request.reservations.push(seed);
          });
          this.request.reservations = this.sortByKey(
            this.request.reservations,
            "date",
            true
          );
        });
      });
    },
    getLatestContractChange(home_id) {
      if (home_id) {
        getRentalContractChangeList(
          `home_id=${home_id}&change_type=RENEW`
        ).then(({ data }) => {
          let mapped = data.filter((item) => item.status !== 74);
          let sorted = mapped.sort((a, b) => b.id - a.id);
          if (sorted != null && sorted.length > 0) {
            this.contractChange = sorted[0];
          }
        });
      }
    },
    openReport(url) {
      getPdfBlob(url).then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
        this.loadingDialog = false;
      });
    },
    checkCanOpen(url) {
      this.loadingDialog = true;
      switch (url) {
        case "deposit":
          depositCheck(this.leaseContract.id)
            .then((res) => {
              this.openReport(
                `${this.API_URL}/report/bill/deposit/${this.leaseContract.id}`
              );
            })
            .catch((error) => {
              this.$store.commit("ERROR", "리포트를 생성할 데이터가 없습니다.");
              this.loadingDialog = false;
            });
          break;
        case "rentFee":
          rentFeeCheck(this.leaseContract.id, this.rentFeeYear)
            .then((res) => {
              this.openReport(
                `${this.API_URL}/report/bill/rent/${this.leaseContract.id}/${this.rentFeeYear}`
              );
            })
            .catch((error) => {
              this.$store.commit("ERROR", "리포트를 생성할 데이터가 없습니다.");
              this.loadingDialog = false;
            });
          break;
        case "renewal":
          renewalCheck(this.leaseContract.id)
            .then((res) => {
              this.openReport(
                `${this.API_URL}/report/renewal/${this.leaseContract.id}`
              );
            })
            .catch((error) => {
              this.$store.commit("ERROR", "리포트를 생성할 데이터가 없습니다.");
              this.loadingDialog = false;
            });
          break;
        case "right":
          rightCheck(this.leaseContract.id)
            .then((res) => {
              this.openReport(
                `${this.API_URL}/report/right/${this.leaseContract.id}`
              );
            })
            .catch((error) => {
              this.$store.commit("ERROR", "리포트를 생성할 데이터가 없습니다.");
              this.loadingDialog = false;
            });
          break;
      }
    },
    setRentFeeYearAndOpen(year) {
      this.rentFeeYear = year;
      this.checkCanOpen("rentFee");
    },
    reservationView(type, path) {
      if (
        [
          "CONTRACT_RESERVATION",
          "JOINT_RESERVATION",
          "PAYMENT_RESERVATION",
          "WIN_RESERVATION",
          "PRE_WIN_RESERVATION",
          "MOVEIN_WIN_RESERVATION",
        ].includes(type)
      ) {
        this.$router.push({
          name: "commonReservationView",
          query: { reservation_code: type },
        });
      } else if (type == "PRE_CHECK_RESERVATION") {
        this.$router.push({ name: "preCheck" });
      } else if (type == "VISIT_RESERVATION") {
        this.$router.push({ name: "visitReservation" });
      } else if (type == "MOVEIN_RESERVATION") {
        this.$router.push({ name: "moveInReservationView" });
      } else {
        this.$router.push({ path: path });
      }

      //TODO : 당첨자,예비당첨자
    },
    showNumber(name) {
      return name.includes("충북");
    },
    sortByKey(arr, key, reverse = false) {
      return arr.sort(function (a, b) {
        var keyA = a[key],
          keyB = b[key];
        if (reverse) {
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
        } else {
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
        }

        return 0;
      });
    },
  },
  created() {
    this.$store.dispatch("USER_REFRESH");
    this.getRequestList();
    this.rentFeeYear = this.rentFeeList[0];
    this.getLatestContractChange(this.leaseContract?.id);
  },
};
</script>
