<template>
    <v-container class="vh-footer ps-re lynn-mobile lynn-web-in pd0" fluid>
        <div class="web-side"></div>
        <div class="location-box np">
            <div class="web-logo">
                <Weblogo width="55" height="30" />
            </div>
            <a @click="moveTo('/contract')" class="back">
                <Back />
            </a>
            <strong class="lo-title">임대료 조회</strong>
        </div>
        <v-container class="pd0 ps-re">
            <LeasePayment :hideCustomText="true" />
        </v-container>

    </v-container>
</template>

<style></style>

<script>
import LeasePayment from "@/components/Contract/LeasePayment";
import Weblogo from "@/assets/images/common/new-logo.svg";
import Back from "@/assets/images/icon/icon-back.svg";

export default {
    components: {
        Weblogo,
        Back,
        LeasePayment, // 보증금/임대료 - 임대료 납부/미납 내역
    },
    name: "ContractInfo",
    data() {
        return {
        };
    },
    watch: {
    },
    methods: {
        moveTo(path) {
            this.$router.push({ path: path });
        },
    },
    computed: {
        home() {
            return this.$store.getters.getHome ? this.$store.getters.getHome : {};
        },
    },
    created() {
    },
};
</script>
