<template>
  <v-container class="vh-footer bg-gray ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pad nb mt15 mt-p3 web-contract">
      <div class="resinfo-list" v-if="isSystemCheck"
        style="text-align: left; padding: 10px; font-size: 14px; font-weight: 400">
        <div v-html="temporaryNoticeHtml()"></div>
      </div>
      <div class="web-side"></div>
      <div class="mypi-contbox">
        <!-- 계약 단지가 하나일땐 그냥 단지명 보여줌 -->
        <strong class="t-cont-title text-center" v-if="contractList.length === 1">{{
          contractList[0].text
        }}</strong>

        <!-- 단지가 2개 이상일 땐 셀렉트 박스 -->
        <strong class="t-cont-title" v-if="contractList.length > 1">계약정보</strong>
        <div class="cus-sel">
          <v-select v-if="contractList.length > 1" v-model="selHome" class="sel-cus" :items="contractList"
            item-text="text" return-object menu-props="auto" hide-details single-line></v-select>
        </div>
      </div>
      <!-- 메뉴 커스텀 단지 / ul 단위로 이렇게 별도 구성이 관리 용이할것으로 보임 -->
      <ul class="list-box" v-if="isOnlyRentVillage">
        <li v-if="home.is_rent" @click="moveto('/rent/receipt')">
          <a class="l-btn">임대료 조회</a>
          <a class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconsecond />
              </span>
            </span>
          </a>
        </li>
      </ul>

      <!-- 분양 -->
      <ul class="list-box" v-else>
        <li v-if="!home.is_rent">
          <a @click="moveto('/contractInfo/status/sell')" class="l-btn">계약내용 조회</a>
          <a @click="moveto('/contractInfo/status/sell')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconfirst />
              </span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent">
          <a class="l-btn" @click="moveto('/contractInfo/payment/payment')">납부현황 조회</a>
          <a class="r-btn" @click="moveto('/contractInfo/payment/payment')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconsecond />
              </span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent && !hideMenuCustom01">
          <a class="l-btn" @click="moveto('/contractInfo/loan/loan')">융자현황 조회</a>
          <a class="r-btn" @click="moveto('/contractInfo/loan/loan')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconthird />
              </span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent && !hideMenuCustom01">
          <a class="l-btn" @click="moveto('/contractInfo/interest/interest')">대납이자·보증수수료</a>
          <a class="r-btn" @click="moveto('/contractInfo/interest/interest')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconforth />
              </span>
            </span>
          </a>
        </li>
        <li v-if="!home.is_rent && !hideMenuCustom01">
          <!--disabled 넣으면 비활성화 느낌으로 변경-->
          <a class="l-btn" @click="moveto('/contractInfo/balance/balance')">잔금납부 안내</a>
          <a class="r-btn" @click="moveto('/contractInfo/balance/balance')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconsecond />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent">
          <a @click="moveto('/contractInfo/status/rent')" class="l-btn">계약내용 조회</a>
          <a @click="moveto('/contractInfo/status/rent')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconfirst />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent">
          <a class="l-btn" @click="moveto('/contractInfo/receipt/deposit')">보증금·임대료 조회</a>
          <a class="r-btn" @click="moveto('/contractInfo/receipt/deposit')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconsecond />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.erp_village_id == 'AA00P0267D'">
          <a class="l-btn" @click="moveto('/contractInfo/loan/loan')">융자현황 조회</a>
          <a class="r-btn" @click="moveto('/contractInfo/loan/loan')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconthird />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.erp_village_id == 'AA00P0267D'">
          <a class="l-btn" @click="moveto('/contractInfo/interest/interest')">대납이자·보증수수료</a>
          <a class="r-btn" @click="moveto('/contractInfo/interest/interest')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconforth />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.CHANGE">
          <a class="l-btn" @click="moveto('/contractInfo/request/change')">계약 변경 방문 예약</a>
          <a class="r-btn" @click="moveto('/contractInfo/request/change')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconsixth />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.RENEW">
          <a class="l-btn" @click="moveto('/contractInfo/renew/renew')">계약 갱신 방문 예약</a>
          <a class="r-btn" @click="moveto('/contractInfo/renew/renew')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconsixth />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.TERMINATE_BEFORE_CONTRACT">
          <a class="l-btn" @click="moveto('/contractInfo/cancel/cancel')">계약 해지 방문 예약</a>
          <a class="r-btn" @click="moveto('/contractInfo/cancel/cancel')">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <Iconfifth />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.TERMINATE_BEFORE_PERIOD">
          <a class="l-btn" @click="moveto('/contractInfo/before/leave')">중도 퇴거 신청</a>
          <a @click="moveto('/contractInfo/before/leave')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <IconMoveOut />
              </span>
            </span>
          </a>
        </li>
        <li v-if="home.is_rent && home.contract_reservation.TERMINATE_ON_PERIOD">
          <a class="l-btn" @click="moveto('/contractInfo/on/leave')">만기 퇴거 신청</a>
          <a @click="moveto('/contractInfo/on/leave')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <IconMoveOut />
              </span>
            </span>
          </a>
        </li>
      </ul>

      <ul class="list-box" v-if="oepnMoveToMoveIn">
        <li class="done single">
          <router-link to="/moveinReservationList">
            <div class="dw-sbox type1">
              <div class="bg">
                <img src="@/assets/images/dwelling/topbg2.png" />
              </div>
              <div class="text-box">
                <strong>입주예약 바로가기</strong>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <ul class="list-box" v-if="openMoveToPreCheck">
        <li class="done single">
          <router-link to="/precheckReservationList">
            <div class="dw-sbox type1">
              <div class="bg">
                <img src="@/assets/images/dwelling/topbg2.png" />
              </div>
              <div class="text-box">
                <strong>사전점검 방문예약 바로가기</strong>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <ul class="list-box" v-if="home.is_defect_pre_open">
        <li class="done single mb-1">
          <div @click="openDefectView('PRE')">
            <div class="dw-sbox type2">
              <div class="bg">
                <img src="@/assets/images/dwelling/topbg3.png" />
              </div>
              <div class="text-box">
                <strong>사전점검 AS접수/내역</strong>
              </div>
            </div>
          </div>
        </li>
        <li class="done single">
          <div @click="openDefectView('AFTER')" v-if="home.is_defect_after_open">
            <div class="dw-sbox type2">
              <div class="bg">
                <img src="@/assets/images/dwelling/topbg3.png" />
              </div>
              <div class="text-box">
                <strong>AS접수(입주)</strong>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- 예약 목록 -->
      <!-- TODO : 신청 열린 예약 목록만 보여줌 -->
      <!-- 아이콘
          IconReserve : 계약자, 중도금신청, 공동명의신청
          IconRev : 사전점검예약
          IconHomein : 입주예약 -->
      <strong class="t-cont-title"> 진행중인 방문 예약 </strong>

      <ul class="list-box">
        <li class="single" v-for="item in reservableList" :key="`contract-reservation-${item.id}`">
          <a @click="moveToReservation(item)" class="l-btn">
            <span class="text-item">{{ item.title }}</span>
            <span class="date-item">방문일정 : {{ item.start_date }} ~ {{ item.end_date }}</span>
          </a>
          <a @click="moveToReservation(item)" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <IconRev v-if="['PRE_CHECK_RESERVATION'].includes(item.kind_code)" />
                <IconHomein v-else-if="['MOVEIN_RESERVATION'].includes(item.kind_code)" />
                <IconReserve v-else />
              </span>
            </span>
          </a>
        </li>

        <li class="single" v-if="!reservableList.length">
          <a @click="moveToReservation(null)" class="l-btn">
            <span class="text-item">현재 예약 가능한 방문예약이 없습니다.</span>
          </a>
          <a @click="moveToReservation(null)" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box">
                <IconReserve />
              </span>
            </span>
          </a>
        </li>
        <!-- <li>
          <a class="l-btn" @click="moveto('/inspectionList')">사전점검 AS 접수</a>
          <a @click="moveto('/inspectionList')" class="r-btn">
            <span class="ng-box">
              <span class="top-box"></span>
              <span class="bottom-box"><Iconfirst /></span>
            </span>
          </a>
        </li> -->
      </ul>
    </v-container>

    <SurveyDialog v-if="survey.dialog" :survey="survey" @close="survey.dialog = false" />
  </v-container>
</template>

<script>
import {
  getMoveInReservationMaster,
  getOpenMoveInVillage,
  getReservableList,
} from "@/api/index.js";
import { checkSurvey } from "@/api/survey";
import Iconfirst from "@/assets/images/contract/1.svg"; //계약현황
import Iconsecond from "@/assets/images/contract/2.svg"; //수납현황, 보증금·임대료
import Iconthird from "@/assets/images/contract/3.svg"; //융자신청·납부
import Iconforth from "@/assets/images/contract/4.svg"; //대납이자 납부
import Iconfifth from "@/assets/images/contract/5.svg"; //계약 해지
import Iconsixth from "@/assets/images/contract/6.svg"; //계약변경·갱신
import IconRev from "@/assets/images/homein/1.svg"; //사전점검 예약
import IconReserve from "@/assets/images/homein/2.svg"; //A/S 접수
import IconHomein from "@/assets/images/homein/3.svg"; //입주 예약
import IconMoveOut from "@/assets/images/homein/4.svg"; //퇴거 신청
import SurveyDialog from "@/components/Survey/SurveyDialog";
import { get_now_string } from "@/utils/time.js";
export default {
  components: {
    Iconfirst,
    Iconsecond,
    Iconthird,
    Iconforth,
    Iconfifth,
    Iconsixth,
    IconReserve,
    IconRev,
    IconHomein,
    IconMoveOut,
    SurveyDialog
  },
  name: "Contract",
  data: () => ({
    selHome: "",
    reservableList: [], // 예약 가능한 예약 목록
    moveInReserved: [], // 예약된 단지 목록
    loaded: false,

    survey: {
      dialog: false,
      popup_period: null
    }
  }),
  computed: {
    home() {
      return this.$store.getters.getHome;
    },
    contractList() {
      return this.$store.getters.getContractList;
    },
    user() {
      return this.$store.getters.getUser;
    },
    isSystemCheck() {
      let now = new Date();
      let start = new Date("2024-05-16 21:00");
      let end = new Date("2024-05-16 22:30");
      return start <= now && now <= end;
    },
    oepnMoveToMoveIn() {
      return this.home && this.home.is_movein_reservation_open;
    },
    openMoveToPreCheck() {
      return this.home && this.home.is_defect_reservation_open;
    },
    hideReservation() {
      // 입주예약 또는 사전점검 예약 오픈 시, 부하 줄이기 위해 일반 예약 조회하지않음
      return this.oepnMoveToMoveIn || this.openMoveToPreCheck;
    },
    browserInfo() {
      return navigator.userAgent.toLowerCase();
    },
    is_app() {
      return this.browserInfo.indexOf("wikey_flutter") > 0;
    },
    is_development() {
      return process.env.NODE_ENV !== "production";
    },
    hideMenuCustom01() {
      return this.home && this.home.erp_village_id == "AA00P0167D";
    },
    isOnlyRentVillage() {
      /*
      2024-12-31 현재 센터스퀘어 등촌 임대의 경우 운영 이관으로 계약정보 상 불일치가 발생할 수 있어 미사용.
      임대 메뉴만 사용, 해당 메뉴는 별도 단일 페이지로 구성됨
      */
      return this.home && this.home.erp_village_id == "BQ00P0503D";
    }
  },
  watch: {
    selHome() {
      this.$store.commit("SET_HOME", this.selHome.id);

      // Update ReservableList on Switch home
      if (this.loaded) {
        this.reservableList = [];
        this.moveInReserved = [];
        this.getReservableListAPI();
      }
    },

    // @@@@ 사전점검 행사 중, 웹으로 전환해야할 시 selHome()을 아래로 바꿔주세요.
    // selHome() {
    //   this.$store.commit("SET_HOME", this.selHome.id);
    //   //this.callFlutter(); //기존버전으로 전환 시 callFlutter() 주석처리
    //
    //   // Update ReservableList on Switch home
    //   if (this.loaded) {
    //     this.reservableList = [];
    //     this.moveInReserved = [];
    //     this.getReservableListAPI();
    //   }
    // },
  },
  methods: {
    getReservableListAPI() {
      // 양주옥정 입주예약 (임시)
      if (this.hideReservation) {
        return;
      }

      // 당첨자,예비당첨자는 제외 is_win=N
      getReservableList(this.home.id, "is_win=N").then((res) => {
        this.reservableList = res.data.filter((r) => r.kind_code != "MOVEIN_RESERVATION");

        getOpenMoveInVillage().then((res2) => {
          res2.data.forEach((village) => {
            if (village.id == this.home.village) {
              let query = `date=${get_now_string()}&village=${this.home.village
                }&kind_code=MOVEIN_RESERVATION&no_dong=${this.home.dong_no}&no_ho=${this.home.ho_no}`;

              getMoveInReservationMaster(query).then((res3) => {
                village = res3.data;
                this.reservableList.splice(0, 0, village);
                this.loaded = true;
              });
            }
          });
        });
      });
    },
    moveto(tab) {
      /*
        'RENEW' = '갱신'
        'CHANGE' = '변경'
        'TERMINATE_BEFORE_CONTRACT' = '미입주 해지'
        'TERMINATE_BEFORE_PERIOD' = '중도 퇴거'
        'TERMINATE_ON_PERIOD' = '만기 퇴거'

        home.contract_reservation.RENEW = true or false
        home.contract_reservation.CHANGE = true or false
        ...
      */
      if (tab == "/contractInfo/balance/balance") {
        if (!this.home.is_display_balance_info) {
          this.$store.commit("ERROR", "잔금납부 안내 기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/request/change") {
        // 계약 변경 신청 가능 여부
        if (!this.home.contract_reservation.CHANGE) {
          this.$store.commit("ERROR", "계약변경 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/renew/renew") {
        // 계약 갱신 신청 가능 여부
        if (!this.home.contract_reservation.RENEW) {
          this.$store.commit("ERROR", "계약갱신 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/cancel/cancel") {
        // 계약 해지
        if (!this.home.contract_reservation.TERMINATE_BEFORE_CONTRACT) {
          this.$store.commit("ERROR", "계약해지 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/before/leave") {
        // 중도퇴거
        if (!this.home.contract_reservation.TERMINATE_BEFORE_PERIOD) {
          this.$store.commit("ERROR", "중도퇴거 신청기간이 아닙니다.");
          return;
        }
      } else if (tab == "/contractInfo/on/leave") {
        // 만기퇴거
        if (!this.home.contract_reservation.TERMINATE_ON_PERIOD) {
          this.$store.commit("ERROR", "만기퇴거 신청기간이 아닙니다.");
          return;
        }
      }
      this.$router.push({
        path: tab,
      });
    },
    moveToReservation(item) {
      if (!item) {
        // 예약 불가능
        this.$store.commit("ERROR", "예약 가능한 방문예약이 없습니다.");
        return;
      }

      if (!item.is_reservable) {
        // 예약 불가능
        this.$store.commit("ERROR", "신청 기간이 아닙니다.");
        return;
      }

      if (item.kind_code == "MOVEIN_RESERVATION") {
        this.moveToMoveIn(item);
      } else {
        this.$router.push({
          name: "commonReservationForm",
          params: {
            master_id: item.id,
          },
          query: {
            reservation_code: item.kind_code,
            is_verified: "Y",
            verified_date: get_now_string(true),
            home: this.home.id,
          },
        });
      }
    },
    moveToMoveIn(item) {
      this.$router.push({
        name: "moveInReservationForm",
        params: {
          id: 0,
          selectVillageId: item.village,
          village_name: item.village_name,
          dong_no: this.home.dong_no,
          ho_no: this.home.ho_no,
          start_date: item.start_date,
        },
      });
    },

    // 2024-05-16 21:00 ~ 22:30 동안 ERP 미동작 관련 안내 표시
    temporaryNoticeHtml() {
      return '\
        <div>\
          <div style="text-align:center;color:#f36628;"><h3 style="font-weight:500">스마트린 긴급 서버 점검 안내문</h3><br/></div>\
        \
        안녕하세요, 고객님.<br/>\
        더 나은 서비스 제공을 위해 다음과 같이 서버 점검 작업을 진행할 예정입니다. 점검 시간 동안에는 서비스 이용이 일시적으로 제한될 수 있으니 고객님의 양해 부탁드립니다.<br/>\
        <br/>\
        <b style="font-weight:500">점검 일시:</b><br/>\
          <span style="margin-left:10px;">• 2024년 5월 16일 21:00 ~ 22:30</span><br/>\
        <b style="font-weight:500">점검 내용:</b><br/>\
        <span style="margin-left:10px;">• 서버 안정성 및 성능 향상을 위한 정기 점검</span><br/>\
          <span style="margin-left:10px;">• 보안 업데이트 및 시스템 최적화</span><br/>\
        </div>\
      ';
    },
    // Survey
    checkSurvey() {
      const query = {
        location: "contract",
        skip_ids: this.$store.getters.getSurveySkipList,
      }
      checkSurvey(this.home.id, query).then(({ data }) => {
        console.log(data)
        this.survey = data
        this.survey.dialog = true
      })
    },

    openDefectView(defectType) {
      let to = ""
      let isSettingOpen = false
      let endAt = ""
      let type = ''

      if (defectType == "PRE") {
        to = "/defects/pre/list?type=PRE"
        isSettingOpen = this.home.is_defect_pre_open
        endAt = this.home.defect_pre_end_at
        type = "PRE"
      } else if (defectType == "AFTER") {
        to = "/defects/list?type=AFTER"
        isSettingOpen = this.home.is_defect_after_open
        endAt = this.home.defect_after_end_at
        type = "MOVE_IN"
      }
      if (!endAt) {
        endAt = ""
      }

      this.callFlutter(type, isSettingOpen, endAt)
      this.$router.push(to)
    },
    // home의 사전점검여부에 따라 flutter 호출
    callFlutter(defectType, isSettingOpen = false, endAt = null) {
      // Note: 로직 변경 시, Contract.vue/DwellingIntro.vue 둘 다 변경 필요

      // 리듬시티 사전점검만 flutter 앱 사용
      if (this.home.erp_village_id != "AA00P0267D") return;

      // 해당 하자접수 오픈여부 체크
      if (!isSettingOpen) return;

      console.log("run callFlutter type: ", defectType, " is App", this.is_app)
      if (this.is_app) {
        let dong = this.home.dong_no;
        let ho = this.home.ho_no;
        let siteCode = this.home.erp_village_id;
        let siteName = this.home.village_name;
        let home_id = this.home.id;
        let email = this.user.email;
        // 사전점검 종료일자 
        // let endAt = endAt;
        window.flutter_inappwebview
          .callHandler(
            "setCustomerDefectData",
            defectType,
            siteCode,
            siteName,
            dong,
            ho,
            home_id,
            email,
            endAt,
          )
          .then(function (result) {
            console.log(
              "@@@ flutterInAppWebViewPlatformReady setCustomerDefectData  result: ",
              result
            );
            if (!result) {
              alert("새 버전이 있습니다. 앱 업데이트를 실행해주세요.");
            }
          }).catch((e) => {
            console.log("###e", e);
            alert("새 버전이 있습니다. 앱 업데이트를 실행해주세요.");

            // client error 저장
            this.$store.dispatch("LOGGING", {
              error: e,
              tags: `flutter_inappwebview|setCustomerDefectData()`,
              etc: {},
            });
          });
      }
    },
  },
  created() {
    // this.$store.dispatch("USER_REFRESH");
    this.selHome = this.home;

    if (!this.contractList.length) {
      this.$store.commit("SUCCESS", "계약자를 위한 서비스입니다.");
      this.$router.push({ name: "mypage" });
    } else {
      let hasHome = this.contractList.find((c) => c.id == this.selHome.id);
      if (!hasHome) {
        this.selHome = this.contractList[0];
      }

      this.getReservableListAPI();
    }

    this.checkSurvey();
  },
};
</script>
